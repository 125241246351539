exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about_us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-cocoichibanya-index-jsx": () => import("./../../../src/pages/cocoichibanya/index.jsx" /* webpackChunkName: "component---src-pages-cocoichibanya-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-cowarking-index-jsx": () => import("./../../../src/pages/cowarking/index.jsx" /* webpackChunkName: "component---src-pages-cowarking-index-jsx" */),
  "component---src-pages-delivery-index-jsx": () => import("./../../../src/pages/delivery/index.jsx" /* webpackChunkName: "component---src-pages-delivery-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-quest-color-index-jsx": () => import("./../../../src/pages/quest_color/index.jsx" /* webpackChunkName: "component---src-pages-quest-color-index-jsx" */),
  "component---src-pages-quest-promotion-index-jsx": () => import("./../../../src/pages/quest_Promotion/index.jsx" /* webpackChunkName: "component---src-pages-quest-promotion-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-pages-syugetsu-index-jsx": () => import("./../../../src/pages/syugetsu/index.jsx" /* webpackChunkName: "component---src-pages-syugetsu-index-jsx" */),
  "component---src-pages-tanny-support-index-jsx": () => import("./../../../src/pages/tanny_support/index.jsx" /* webpackChunkName: "component---src-pages-tanny-support-index-jsx" */),
  "component---src-pages-temple-index-jsx": () => import("./../../../src/pages/temple/index.jsx" /* webpackChunkName: "component---src-pages-temple-index-jsx" */),
  "component---src-pages-uwajima-quest-index-jsx": () => import("./../../../src/pages/uwajima_quest/index.jsx" /* webpackChunkName: "component---src-pages-uwajima-quest-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

